import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const AuthorBox = () => {
  const {
    file: {
      childImageSharp: { fluid: profileImage },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "authors/mathias.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="container my-xl">
      <section className="grid grid-cols-1 md:grid-cols-1-3 rounded shadow-md">
        <article>
          <Image
            fluid={profileImage}
            alt="forfatter"
            className="rounded-tl rounded-bl h-72"
          />
        </article>
        <article className="bg-white p-lg rounded-br rounded-tr">
          <h3>Skrevet af Mathias</h3>
          <p>
            Mit navn er Mathias, og jeg er forfatter på Opholdsguide. Jeg er
            vild med at tage på ophold med min kæreste og familie, og de
            oplevelser og erfaringer vil jeg gerne dele med jer. Jeg ønsker dig
            en rigtig god tur!
          </p>
          <p className="mt-sm">
            Kontakt mig på:{" "}
            <a href="mailto:mathias@opholdsguide.dk" className="link-blue">
              mathias@opholdsguide.dk
            </a>
          </p>
        </article>
      </section>
    </div>
  )
}

export default AuthorBox
