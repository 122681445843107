import React from "react"
import Image from "gatsby-image"
import { FaAngleUp, FaAngleDown } from "react-icons/fa"

/*
  An infobox is a component that is able to toggle display/hidden. It will show information about the given area.
  example: https://opholdsguide.dk/gourmetophold/jylland

@props: {
  info: {
    info: string;
    image: queried gatsby image
  }
  classNumber: string;
  text: string;
  onClick: function;
  display: boolean;
}
*/

const Infobox = ({ info, classNumber, text, onClick, display }) => {
  return (
    <>
      <article className={`infobox-wrapper-${classNumber} rounded shadow-md`}>
        <Image
          fluid={info.image.childImageSharp.fluid}
          alt={info.title}
          className="h-56 w-full rounded-t"
        />
        <button
          className="bg-white w-full py-md font-semibold rounded-b hover:bg-darkSand duration-300 flex justify-center items-center"
          onClick={onClick}
        >
          {info.title}{" "}
          {display ? (
            <FaAngleUp className="ml-2" />
          ) : (
            <FaAngleDown className="ml-2" />
          )}
        </button>
      </article>
      <article
        className={`infobox-text-${classNumber} text-content mt-lg ${
          display ? "block" : "hidden"
        } duration-1000 origin-top`}
        dangerouslySetInnerHTML={{ __html: text }}
      ></article>
    </>
  )
}

export default Infobox
