import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { FaLongArrowAltRight } from "react-icons/fa"

const Linkbox = ({ title, description, image, link }) => {
  return (
    <>
      <article className={`rounded shadow-md bg-white`}>
        <Image
          fluid={image.childImageSharp.fluid}
          alt={title}
          className="h-56 w-full rounded-t"
        />
        <div className="p-lg ">
          <h3>{title}</h3>
          <p>{description}</p>

          <Link
            className="bg-green hover:bg-hoverGreen text-white py-md px-lg text-center inline-flex items-center uppercase mt-md"
            to={link}
          >
            Læs guide <FaLongArrowAltRight className="ml-2" />
          </Link>
        </div>
      </article>
    </>
  )
}

export default Linkbox
