import React, { useState } from "react"
import Infobox from "./Infobox"

/*
  Renders the infoboxes on the pages as described in the /data/hotels/*.json files

@props: {
  infoboxes: {
    content: string;
  }[]
}
*/

const InfoboxesList = ({ infoboxes }) => {
  const [displayState, setDisplayState] = useState({
    boxOne: false,
    boxTwo: false,
  })

  const onClick = box => {
    if (box === "boxOne") {
      setDisplayState({
        boxTwo: false,
        boxOne: !displayState.boxOne,
      })
    }
    if (box === "boxTwo") {
      setDisplayState({
        boxOne: false,
        boxTwo: !displayState.boxTwo,
      })
    }
  }

  return (
    <section className="container infoboxGrid mt-xl">
      <Infobox
        info={infoboxes[0]}
        text={infoboxes[0].content}
        display={displayState.boxOne}
        onClick={() => onClick("boxOne")}
        classNumber={"1"}
      />
      <Infobox
        info={infoboxes[1]}
        text={infoboxes[1].content}
        display={displayState.boxTwo}
        onClick={() => onClick("boxTwo")}
        classNumber={"2"}
      />
    </section>
  )
}

export default InfoboxesList
