import React, { useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"

import loadable from "@loadable/component"
import HotelMap from "./HotelMap"
const Menu = loadable(() => import("./Menu"))

// list includes all hotels on the specific page {name,description,isFavorite,isBestAtPrice}
const HotelsList = ({ list, filterLocation }) => {
  // Queries all hotels from data/hotels.json to grab static information
  const staticData = useStaticQuery(graphql`
    {
      allHotelsJson {
        edges {
          node {
            id
            classes
            link
            location
            name
            price
            stars
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  // Maps props.list to be both static and dynamic data // INIT RENDER
  const staticHotels = list.map(hotel => {
    const { node: staticHotel } = staticData.allHotelsJson.edges.find(
      ({ node }) => node.name === hotel.name
    )
    return { ...staticHotel, ...hotel }
  })
  const [hotelState] = useState([...staticHotels])

  const [filterState, setFilterState] = useState({
    location: "",
    price: "",
    stars: "",
  })

  const setLocation = (location = "") => {
    if (filterState.location === location)
      setFilterState({ ...filterState, location: "" })
    else setFilterState({ ...filterState, location })
  }
  const setFilterProp = (prop, val = "") => {
    if (filterState[prop] === val)
      setFilterState({ ...filterState, price: "", stars: "" })
    else setFilterState({ ...filterState, price: "", stars: "", [prop]: val })
  }

  const containerRef = useRef(null)
  return (
    <ListContext.Provider
      value={{
        hotels: hotelState,
        filter: filterState,
        setLocation,
        setFilterProp,
      }}
    >
      <div className="container relative" ref={containerRef}>
        <Menu displayLocation={filterLocation} />

        <HotelMap />
      </div>
    </ListContext.Provider>
  )
}

export default HotelsList
export const ListContext = React.createContext()
