import React from "react"
import Linkbox from "./Linkbox"

const InfoboxesList = ({ boxes }) => {
  const linkboxes = boxes.map((box, index) => {
    return (
      <Linkbox
        key={index}
        title={box.title}
        description={box.description}
        image={box.image}
        link={box.link}
      />
    )
  })

  return (
    <section className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-lg mt-xl">
      {linkboxes}
    </section>
  )
}

export default InfoboxesList
