import React from "react"
import { FaStar, FaStarHalfAlt } from "react-icons/fa"

const Stars = ({ numOfStars }) => {
  const generateStars = () => {
    const stars = []
    for (let i = 0; i < Math.floor(numOfStars); i++) {
      stars.push(<FaStar className="fill-yellow text-xl" key={i} />)
    }

    if (numOfStars.toString().includes(".5"))
      stars.push(
        <FaStarHalfAlt className="fill-yellow text-xl" key={stars.length} />
      )
    return stars
  }
  return (
    <>
      <p className="flex">{generateStars()}</p>
    </>
  )
}

export default Stars
