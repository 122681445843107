import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/Layout"
import CoverImage from "../components/global/CoverImage"
import BreadCrumbs from "../components/global/BreadCrumbs"
import HotelList from "../components/hotelFilter/HotelList"
import InfoboxesList from "../components/guideComponents/InfoboxesList"
import LinkboxesList from "../components/guideComponents/LinkboxesList"
import TextContent from "../components/TextContent"
import AuthorBox from "../components/AuthorBox"

const Guide = ({ data: { pagesJson } }) => {
  return (
    <Layout
      metaTitle={pagesJson.metaTitle}
      metaDescription={pagesJson.metaDescription}
      slug={pagesJson.slug}
      schema={pagesJson.schema}
    >
      <CoverImage
        image={pagesJson.coverImage.childImageSharp.fluid}
        title={pagesJson.title}
      />

      <section className="text-center container mt-lg mb-xl">
        <BreadCrumbs breadCrumbs={pagesJson.breadCrumbs} />
        <p className="text-sm my-md text-center">
          Denne side indeholder reklamelinks
        </p>
        <h2>{pagesJson.subTitle}</h2>
        <p>{pagesJson.subDescription}</p>
      </section>
      {pagesJson.hotels && (
        <HotelList
          list={[...pagesJson.hotels]}
          filterLocation={pagesJson.filterLocation}
        />
      )}

      {pagesJson.infoboxes && <InfoboxesList infoboxes={pagesJson.infoboxes} />}
      {pagesJson.linkboxes && (
        <>
          <div className="text-center container">
            <h2>{pagesJson.linkboxes.title}</h2>
            <p>{pagesJson.linkboxes.content}</p>
          </div>

          <LinkboxesList boxes={pagesJson.linkboxes.boxes} />
        </>
      )}

      <TextContent content={pagesJson.textContent}></TextContent>
      <AuthorBox />
    </Layout>
  )
}

export const query = graphql`
  query getPage($slug: String) {
    pagesJson(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      slug
      title
      coverImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      breadCrumbs {
        text
        link
      }
      subTitle
      subDescription
      filterLocation
      hotels {
        name
        description
        favorite
        bestPrice
        loc
      }
      infoboxes {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        content
      }
      linkboxes {
        title
        content
        boxes {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
          link
        }
      }
      textContent
      schema {
        one {
          _context
          _type
          author {
            _type
            name
          }
          itemReviewed {
            _type
            name
          }
          publisher {
            _type
            name
          }
          reviewRating {
            _type
            bestRating
            ratingValue
          }
        }
        two {
          _context
          _type
          mainEntity {
            _type
            acceptedAnswer {
              _type
              text
            }
            name
          }
        }
      }
    }
  }
`

export default Guide
