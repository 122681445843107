import React from "react"
import { Link } from "gatsby"
import { FaAngleRight } from "react-icons/fa"

/*
This component shows the breadcrumbs on landing pages... Gets data from each page in /data/hotels json files
@props: {
  breadCrumbs: {
    text: string;
    link: string;
  }[]
}
*/

const BreadCrumbs = ({ breadCrumbs }) => {
  const renderBreadCrumbs = breadCrumbs.map((breadCrumb, index) => {
    if (index === breadCrumbs.length - 1) {
      return (
        <p key={index} className="font-semibold">
          {breadCrumb.text}
        </p>
      )
    }
    return (
      <React.Fragment key={index}>
        <Link to={breadCrumb.link}>{breadCrumb.text}</Link>{" "}
        <FaAngleRight className="mr-2 ml-2" />
      </React.Fragment>
    )
  })
  return (
    <div className="flex items-center justify-center mt-lg text-sm">
      <Link to="/">Ophold</Link> <FaAngleRight className="mr-2 ml-2" />
      {renderBreadCrumbs}
    </div>
  )
}

export default BreadCrumbs
