import React from "react"
import { ListContext } from "./HotelList"
import Hotel from "../hotels/Hotel"

const HotelMap = () => {
  return (
    <ListContext.Consumer>
      {({ hotels, filter }) => {
        let newHotels = [...hotels]
        if (filter.location) {
          newHotels = hotels.filter(hotel => {
            return hotel.loc === filter.location
          })
        }
        if (filter.price === "ASC") {
          newHotels.sort((a, b) => {
            let aprice = a.price[1] === "." ? a.price.replace(".", "") : a.price
            let bprice = b.price[1] === "." ? b.price.replace(".", "") : b.price
            return aprice - bprice
          })
        }

        if (filter.stars === "DESC") {
          newHotels.sort((a, b) => b.stars - a.stars)
        }

        return newHotels.map((hotel, index) => (
          <Hotel key={index} hotel={hotel} />
        ))
      }}
    </ListContext.Consumer>
  )
}
export default HotelMap
